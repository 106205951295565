// Widget
.widget {
  background: $light-blue;
  margin-bottom: $xl-size;
}

.widget__message {
  border-bottom: 1px solid lighten($light-blue, 10%);
  color: $off-white;
  margin: 0;
  padding: $l-size;
  text-align: center;
}

// Widget header
.widget-header {
  background: $blue;
  color: $off-white;
  display: flex;
  justify-content: space-between;
  padding: $m-size;
}

.widget-header__title {
  margin: 0;
}
